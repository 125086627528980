body {
    --background-color: rgb(103, 105, 106);
}

markdown-body {
    background-color: var(--background-color);
}
.markdown-editor,
.rendered-output {
    background-color: var(--background-color);
    color: black;
    height: 100%;
    width: 100%;
    padding: 10px;
}

.markdown-textarea,
.rendered-html-output {
    background-color: lightgrey;
    color: black;
    height: 60vh;
    width: 100%;
    padding: 10px;
    overflow: auto;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.dark-mode {
    background-color: rgba(52, 52, 52) !important;
    color: lightgray !important;
}

.hidden {
    display: none;
}

.btn {
    background-color: #63676c;
    color: white;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    margin: 0;
    height: 100%;
    z-index: 1;
}

.btn-clear {
    background-color: #44484c;
    color: rgb(255, 111, 0);
    font-weight: bolder;
}

.smallIcon {
    margin: 0 auto;
}

.clearIcon {
    background: none;
    border: none;
    height: 100%;
    text-decoration: underline;
}

.input-group-append {
    margin: 0 auto;
}

.top-bar {
    height: 40px;
    margin-bottom: 5px;
    padding-bottom: 2px;
    margin-top: 5px;
}

.form-control-title {
    margin-left: 10px;
    margin-bottom: 1px;
    display: inline;
    background: inherit;
    font: inherit;
    height: 100%;
    width: 60%;
    max-width: 300px;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border-bottom: 1px solid #ced4da !important;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.alert-style {
    background-color: #44484c;
    color: rgb(255, 111, 0);
    font-weight: bolder;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
}

/* Style alerts */
.alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.list-group-item {
    background: none;
    border: none;
}

/* orange links */
a {
    color: rgb(255, 111, 0);
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active, .collapsible:hover {
    background-color: #ccc;
  }
  
  /* Style the collapsible content. Note: hidden by default */
  .content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #f1f1f1;
  }

  .notesList {
      margin-bottom: 45px;;
  }

  /* Popup style */
.popup-box {
    position: fixed;
    background: #00000050 !important;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }

  .helpButton {
      color:rgb(255, 111, 0) !important;

    }

  .helpInput {
    inherits: form-control-title;
    width: 100% !important;
    max-width: 500px !important;
  }

  .invisible {
    display: none;
  }

  a {
      color:rgb(255, 111, 0);
  }